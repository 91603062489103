import React, { Component } from "react";
import PropTypes from "prop-types";
import EventList from "components/event-list";
import { getEvents } from "api/events";
import PrimaryButton from "components/button/primary";
import LinkButton from "components/button/link";
import Tabs, { Tab } from "components/tabs";
import Loader from "components/loader";
import "./styles.css";

class Events extends Component {
  static propTypes = {
    limit: PropTypes.number
  };

  static defaultProps = {
    limit: 10
  };

  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      loading: true,
      selectedMode: "live",
      events: [],
      error: null
    };
  }

  componentDidMount() {
    this.loadEvents(true);
  }

  loadEvents = (clear, mode = this.state.selectedMode) => {
    const { payload } = this.state;
    const offset = clear ? 0 : payload.nextOffset;
    this.setState({ loading: true });
    getEvents(this.props.limit, offset, mode)
      .then(payload => {
        this.setState({
          payload,
          events: clear ? payload.data : this.state.events.concat(payload.data)
        });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({
          error: e,
          loading: false
        });
      });
  };

  handleLoadMoreClicked = () => {
    this.loadEvents();
  };

  handleActiveTabChanged = (index, value) => {
    this.setState({ events: [], selectedMode: value });
    this.loadEvents(true, value);
  };

  render() {
    const { loading, payload, events, error, selectedMode } = this.state;

    if (error) throw error;

    const draftTitle =
      payload && payload.metadata && payload.metadata.draftEventCount > 0
        ? `Draft (${payload.metadata.draftEventCount})`
        : "Draft";

    return (
      <div className="events">
        <Tabs onChange={this.handleActiveTabChanged}>
          <Tab title="Live" value="live" />
          <Tab title={draftTitle} value="draft" />
          <Tab title="Past" value="past" />
        </Tabs>

        {payload && <EventList events={events}>Event list</EventList>}

        <div className="events-load-more">
          {!loading && events.length > 0 && payload.nextOffset && (
            <PrimaryButton
              disabled={loading}
              onClick={this.handleLoadMoreClicked}
            >
              Load more
            </PrimaryButton>
          )}
          {loading && <Loader />}

          {!loading && events.length === 0 && (
            <div className="create-event">
              <img
                alt=""
                className="create-event__image"
                src="https://fixr-content-goat.s3.amazonaws.com/static/release_fe2766abe/react/images/tickets.png"
              />
              <div className="create-event__mode">No {selectedMode} events</div>
              <LinkButton href="/events/create">CREATE EVENT</LinkButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Events;
